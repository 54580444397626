
import request from '@/helpers/request';


class TKPSTaskApi {

    new_task_sn = () => {
        return request({
            url: '/api/v1/task/new_sn',
            method: 'get'
        });	
    }


    add_task = (data) => {
        return request({
            url: '/api/v1/task/create',
            method: 'post',
            data
        });	
    }


    list_task = (data) => {
        return request({
            url: '/api/v1/task/list',
            method: 'post',
            data
        });	
    }

    task_detail = (task_number) => {

        return request({
            url: '/api/v1/task/detail/'+task_number,
            method: 'get',
            
        });	
    }


    produce_detail = (task_number, model) => {
        let data = {
            task_number : task_number,
            model       : model
        }
        return request({
            url: '/api/v1/task/produce/detail/',
            method: 'post',
            data
        });	
    }


    produce_transfer = (data) => {
        return request({
            url: '/api/v1/task/produce/transfer',
            method: 'post',
            data
        });	
    }

    list_task_produce_list = (data) => {
       
        return request({
            url: '/api/v1/task/produce_list',
            method: 'post',
            data
            
        });	
    }


    delete_task = (task_id, data) => {
        return request({
            url: '/api/v1/task/delete/'+task_id,
            method: 'post',
            data
        });	
    }



    finish_task = (task_id, data) => {
        return request({
            url: '/api/v1/task/finish/'+task_id,
            method: 'post',
            data
        });	
    }


    task_statistic = () => {
        return request({
            url: '/api/v1/task/statistic',
            method: 'get',
            
        });	
    }


    active_produce_query = () => {
        let data = {}
        return request({
            url: '/api/v1/produce/query',
            method: 'post',
            data
        });	
    }

    
    
}



let _api = null

export const useTaskApi = () => {
    if (!_api) {
        _api = new TKPSTaskApi();
    }
    return _api;
}
