<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { useTaskApi } from "@/apis/task";

/**
 * Project-overview component
 */
export default {
  page: {
    title: "加工型号详情",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "加工型号详情",
      items: [
        {
          text: "生产加工",
        },
        {
          text: "投产任务",
          href: "/produce/task_list",
        },
        {
          text: "任务详情",
          href: "/produce/show_task?parent_task=" + this.$route.query.task_number,
        },
        {
          text: "加工型号详情",
          active: true,
        },
      ],


      produce_detail: {
        events: [],
        logs: [],
        dept: [],
        detail: {},
        person: [],
        task: {}

      }
    };
  },
  components: {
    Layout,
    PageHeader,
  },

  mounted() {
    this.query_produce_detail(this.$route.query.task_number, this.$route.query.model)
  },

  methods: {
    query_produce_detail(task_number, model) {
      useTaskApi().produce_detail(task_number, model).then(res => {
        if (res.err == 0) {
          this.produce_detail = res.data
          this.compute_dept_produce_progress()
        }
      })
    },

    compute_dept_produce_progress() {




      for (let d of this.produce_detail.dept) {
       
        let dp = this.produce_detail.dept_p_list.find(e => e.tag_a == d.t_key)

        if (dp) {
          d.i_count  = dp.i_count
          d.progress =(Number(d.i_count) / 1.0 / Number(this.produce_detail.detail.p_count) * 100).toFixed(0)
        } else {
          d.i_count  = 0
          d.progress = 0
        }
      }

    },

    get_t_count(t_count) {
      return t_count <= 0 ? '0' : t_count
    },

    get_p_progress(p_count, o_count) {
      return (Number(o_count) / 1.0 / Number(p_count) * 100).toFixed(0) +'%'
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div class="avatar-title bg-soft-primary text-primary font-size-18 rounded">
                        D
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-1">{{ produce_detail.detail.model_code }}(自生增压管路支架3)</h5>
                      <p class="mb-0">
                        任务编号: {{ produce_detail.task.task_number }}
                      </p>
                      <p class="mb-0">
                        项目: XY1ACX-03
                      </p>
                      <p class="mb-0">
                        批号: {{ produce_detail.detail.model_number }}
                      </p>
                      <p class="mb-0">
                        材料: {{ produce_detail.fields.material }}
                      </p>
                      <p class="mb-0">
                        工艺: {{ produce_detail.fields.tech }}
                      </p>
                      <p class="mb-0">
                        说明: {{ produce_detail.fields.remarks }}
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">
                  <button type="button" class="btn btn-secondary w-sm" @click="$router.push( {name : 'task-detail', query : {parent_task : $route.query.task_number}} )"> 返回 </button>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
        <b-tabs class="mt-n5 pt-2" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom">
          <b-tab title="总览" active>
            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-list-ul"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          投产数量
                        </p>
                        <h5 class="font-size-16 mb-0">{{ produce_detail.detail.p_count }}</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-store"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          入库数量
                        </p>
                        <h5 class="font-size-16 mb-0">{{ produce_detail.detail.o_count }}</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-exchange"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          流转数量
                        </p>
                        <h5 class="font-size-16 mb-0">{{ get_t_count(produce_detail.detail.i_count) }}</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
              </div>
              <!-- end col -->
              <div class="col-xl-3 col-sm-6">

                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-check"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          完成度
                        </p>
                        <h5 class="font-size-16 mb-0">{{get_p_progress(produce_detail.detail.p_count, produce_detail.detail.o_count)  }}</h5>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>

              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-lg-8">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-4">流转信息</h5>

                    <div class="text-muted">


                      <ul class="ps-3">
                        <li class="py-1" v-for=" (evt) in produce_detail.events" :key="'produce_event_'+evt.id">
                          {{ evt.remarks }}
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-3">进度跟踪 - 总计数量({{ produce_detail.detail.p_count }})</h5>

                    <div>
                      <div class="py-3" v-for="(dept ) in produce_detail.dept" :key="'produce_dept_' + dept.t_key">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <h5 class="font-size-14">{{ dept.t_value }}</h5>
                          </div>
                          <div class="flex-shrink-0">
                            <p class="text-muted mb-0">{{dept.i_count}}/{{ produce_detail.detail.p_count }}</p>
                          </div>
                        </div>
                        <div class="progress animated-progess custom-progress mt-2">
                          <div class="progress-bar" role="progressbar" :style="{width: dept.progress+'%'}" aria-valuenow="60"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- end card -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </b-tab>

          <b-tab title="人员/部门信息">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    相关人员
                    <span class="text-muted fw-normal ms-2">( {{ produce_detail.person.length }})</span>
                  </h5>
                </div>
              </div>

              <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">
                  <div>
                    <ul class="nav nav-pills">

                    </ul>
                  </div>
                  <div>
                    <a href="#" class="btn btn-light"><i class="uil uil-plus me-1"></i> 添加相关人员</a>
                  </div>

                  <b-dropdown right variant="white" size="sm" toggle-class="btn-link text-dark">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="row">

              <div v-for="p in produce_detail.person" :key="'produce_p_' + p.id" class="col-xl-4 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 avatar rounded-circle me-3">
                        <img src="@/assets/images/users/avatar-1.jpg" alt="" class="img-fluid rounded-circle" />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark">{{ p.tag }}</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          {{ p.remarks }}
                        </p>
                      </div>
                      <b-dropdown class="flex-shrink-0" toggle-class="text-body font-size-16 p-0" right variant="white">
                        <template v-slot:button-content>
                          <feather class="icon-xs" type="more-horizontal"></feather>
                        </template>
                        <b-dropdown-item>Edit</b-dropdown-item>
                        <b-dropdown-item>Action</b-dropdown-item>
                        <b-dropdown-item>Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>

                </div>
                <!-- end card -->
              </div>
              <!-- end col -->




            </div>
            <!-- end row -->

          </b-tab>

          <b-tab title="事件">
            <div class="card-header">
              <h5 class="card-title mb-0">事件</h5>
            </div>
            <div class="card-body">
              <div data-simplebar style="max-height: 280px;">
                <div class="list-group list-group-flush">




                  <div class="list-group-item py-3" v-for="(log) in produce_detail.log"
                    :key="'produce_model_log_' + log.id">
                    <div class="d-flex aligm-items-center">

                      <div class="flex-shrink-0 flex-shrink-0 avatar-sm me-3">
                        <div class="avatar-title rounded-circle bg-light text-primary">
                          U
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark">{{ log.field }}</a>
                          <small class="text-muted ms-1">{{ log.value }}</small>
                        </h5>
                        <p class="text-muted mb-0">
                          {{ log.remarks }}
                        </p>

                      </div>


                    </div>
                  </div>
                  <!-- end list group -->


                </div>
              </div>


              <div class="d-flex chat-input-section align-items-start pt-3">
                <div class="flex-shrink-0 flex-shrink-0 avatar-sm me-3">
                  <div class="avatar-title rounded-circle bg-light text-primary">
                    J
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="position-relative d-flex align-items-start">
                    <input type="text" class="form-control chat-input" placeholder="Some text value..." />
                    <div class="chat-input-links d-flex align-items-start">

                      <button type="button" class="btn btn-link text-muted" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="" data-bs-original-title="Attachments" aria-label="Attachments">
                        <i class="uil uil-paperclip"></i>
                      </button>
                      <button type="button" class="btn btn-primary">
                        <i class="uil uil-message"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
